import TopCategories from '~/themes/base/components/category/TopCategories'

export default {
  mixins: [TopCategories],
  data() {
    return {
      gridColumnClass: 'mb-4 mb-md-5',
    }
  },
}
